import React, { useState, useContext, useEffect } from "react"
import GithubHostingSettings from "./hosting/GithubHostingSettings"
import { AppStateContext } from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { message } from "antd"

const ConnectionSettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient();
  const { state } = useContext(AppStateContext)
  const [webflowModal, setWebflowModal] = useState<any>();

  useEffect(() => {
    if(webflowModal) {
      window.addEventListener("message", (e) => {
        console.log(e);
        if(e.data.action && e.data.action === "connect_webflow") {
          webflowModal.close();
          projectClient.addWebflowWebhook(state.project!.id!).then(
            (res) => {
              message.success("Connected to Webflow");
            },
            (error: string) => {
              message.error(error);
            }
          )
        }
      });
    }
  }, [webflowModal]);

  const webflowAuthUrl = (): string => {
    return `https://webflow.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_WEBFLOW_CLIENT_ID}&state=${state.user!.id}&scope=custom_code%3Aread%20custom_code%3Awrite%20sites%3Aread%20pages%3Aread%20authorized_user%3Aread`
  }

  const openWebflow = () => {
    let modal = window.open(webflowAuthUrl(), "_blank");
    setWebflowModal(modal);
  }

  return (
    <div {...props}>
      <div className="translation-single-item">
        <div className="translation-single-item-name-wrapper">
          <div className="icon-wrapper">
            <img
              loading="lazy"
              src="/images/webflow-logo.png"
              alt=""
              className="object-fit-cover"
            />
          </div>
          <div className="translation-single-item-text">Webflow</div>
        </div>
        <button
          className="button-black w-button"
          onClick={() => openWebflow()}
          disabled={state.project!.data.webflow_connected}
        >
          {state.project!.data.webflow_connected
            ? "Connected to Webflow"
            : "Connect Webflow"}
        </button>
      </div>

      <GithubHostingSettings />
    </div>
  )
}

export default ConnectionSettings;
