import React, { useContext, useEffect, useState } from "react"
import { Button } from "@/components/common"
import { Actions } from "@/components/reducers/AppReducer"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import GithubClient from "@/clients/GithubClient"
import { Project, ProjectAwsSetting } from "@/models/Project"
import { message, Switch } from "antd"
import JWTHelper from "@/utilities/JWTHelper"
import GithubIcon from "@/resources/images/github.svg"
import { stat } from "fs"

const GithubHostingSettings: React.ComponentType = () => {
  const projectClient = new ProjectClient()
  const githubClient = new GithubClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [githubUrl, setGithubUrl] = useState("")
  const [githubBranch, setGithubBranch] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState<Window | null>()
  const [isRails, setIsRails] = useState(state.project!.is_rails)
  const [isLaravel, setIsLaravel] = useState(state.project!.is_laravel)
  const [railsFolder, setRailsFolder] = useState(state.project!.rails_folder)
  const [url, setUrl] = useState("")
  const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID
  const redirect_uri = process.env.REACT_APP_GITHUB_REDIRECT_URL
  const scope = "repo,user"

  useEffect(() => {
    let u = ""
    u = `https://github.com/login/oauth/authorize?client_id=${clientId}`
    u += `&redirect_uri=${redirect_uri}`
    u += `&scope=${scope}`
    u += `&state=` + JWTHelper.getUserId()
    setUrl(u)

    if (state.project!.data.github_data) {
      setGithubUrl(state.project!.data.github_data.url)
      setGithubBranch(state.project!.data.github_data.branch)
    }
  }, [])

  useEffect(() => {
    if (modal) {
      window.addEventListener("message", (e) => {
        console.log(e.data)
        if (modal) modal?.close()
        if (e.data) {
          githubClient.createProjectRepo(state.project!.id!, e.data).then(
            (res: any) => {
              state.project!.github_url = res.html_url
              dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
            },
            (error) => {
              message.error(
                "Failed to create Github repo. Please try again later."
              )
            }
          )
        }
      })
    }
  }, [modal])

  const setupGithub = () => {
    let left = window.innerWidth / 2 - 225
    let top = window.innerHeight / 2 - 400

    let m = window.open(
      url,
      "_blank",
      `left=${left},top=${top},width=450,height=800`
    )

    setModal(m)
  }

  const setupGithubUrl = () => {
    if (githubUrl.length === 0) {
      message.error("Please enter Github url.")
      return
    }

    if (githubBranch.length === 0) {
      message.error("Please enter Github branch.")
      return
    }

    state.project!.is_rails = isRails
    state.project!.is_laravel = isLaravel
    state.project!.rails_folder = railsFolder
    state.project!.data.hosting = "github"
    state.project!.data.github_data = {
      url: githubUrl,
      branch: githubBranch,
    }

    setLoading(true)
    projectClient.updateProject(state.project!.id!, state.project!).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div>
      {!state.project!.github_url && (
        <div className="translation-single-item">
          <div className="translation-single-item-name-wrapper">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src="https://assets.website-files.com/625e72e3b276d01282f085dd/62cfd693cfac750cca85dfab_image%2067%20(2).svg"
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">Github</div>
          </div>
          <Button
            label="Connect Github"
            loading={loading}
            className="button-black w-button"
            onClick={setupGithub}
          />
        </div>
      )}

      {state.project!.github_url && (
        <div className="translation-single-item-opened">
          <div className="translation-single-item-left">
            <div className="translation-single-item-name-wrapper-opened">
              <div className="icon-wrapper">
                <img
                  loading="lazy"
                  src={GithubIcon}
                  alt=""
                  className="object-fit-cover"
                />
              </div>
              <div className="translation-single-item-text">Github</div>
            </div>
          </div>
          <div className="translation-single-item-right">
            <div className="w-form">
              {/* <div className="other-single-item-wrapper">
              <div id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7" className="repository-name-wrapper">
                <div></div>
              </div>
              <div id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7" className="repository-name-wrapper">
                <Checkbox onClick={() => updateHosting("github")} checked={hosting === "github"}>Configure Github</Checkbox>
              </div> 
            </div>*/}
              <div className="other-single-item-wrapper">
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                  className="repository-name-wrapper"
                >
                  <div>Github Url</div>
                </div>
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                  className="repository-name-wrapper"
                >
                  <input
                    type="text"
                    className="no-margin w-input"
                    name="Key"
                    value={githubUrl}
                    onChange={(e) => setGithubUrl(e.target.value)}
                    data-name="Key"
                    placeholder="Enter Github url"
                    id="Key"
                  />
                </div>
              </div>
              <div className="other-single-item-wrapper">
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                  className="repository-name-wrapper"
                >
                  <div>Branch</div>
                </div>
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                  className="repository-name-wrapper"
                >
                  <input
                    type="text"
                    className="no-margin w-input"
                    name="Key"
                    value={githubBranch}
                    onChange={(e) => setGithubBranch(e.target.value)}
                    data-name="Key"
                    placeholder="Enter Github Branch"
                    id="Key"
                  />
                </div>
              </div>
              <div className="settings-single-item-wrapper two-column">
                <div className="setting-single-item-text-wrapper">
                  <div className="settings-grid-item-text">Laravel App?</div>
                </div>
                <div className="settings-form-block w-form">
                  <Switch
                    checked={isLaravel}
                    onChange={(checked) => {setIsLaravel(checked); if(checked) setIsRails(!checked)}}
                  />
                </div>
                <div className="setting-single-item-text-wrapper">
                  <div className="settings-grid-item-text">Rails App?</div>
                </div>
                <div className="settings-form-block w-form">
                  <Switch
                    checked={isRails}
                    onChange={(checked) => {setIsRails(checked); if(checked) setIsLaravel(!checked)}}
                  />
                </div>
              </div>
              {isRails && (
                <div className="settings-single-item-wrapper two-column">
                  <div className="setting-single-item-text-wrapper">
                    <div className="settings-grid-item-text">Rails Folder</div>
                  </div>
                  <div className="settings-form-block w-form">
                    <input
                      type="text"
                      className="settings-input-field w-input"
                      name="RailsFolder"
                      value={railsFolder}
                      onChange={(e) => setRailsFolder(e.target.value)}
                      data-name="RailsFolder"
                      placeholder=""
                      id="RailsFolder"
                      required
                    />
                  </div>
                </div>
              )}
              <div className="other-single-item-wrapper">
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                  className="repository-name-wrapper"
                >
                  <div></div>
                </div>
                <div
                  id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                  className="repository-name-wrapper"
                >
                  <Button
                    label="Update"
                    loading={loading}
                    className="button-black w-button"
                    onClick={setupGithubUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GithubHostingSettings
