import React, { useContext, useEffect, useState } from "react"
import { Checkbox, Input, message, Skeleton, Spin } from "antd"
import AddMember from "./AddMember"
import TeamClient from "@/clients/TeamClient"
import { TeamMember } from "@/models/Project"
import { AppStateContext } from "@/components/contexts/AppContext"
import { Paginator } from "@/models/Paginator"
import { Button, ConfirmModal } from "@/components/common"
import UserClient from "@/clients/UserClient"
import { User } from "@/models/User"

const { Search } = Input

const TeamMembers: React.ComponentType = () => {
  const teamClient = new TeamClient()
  const userClient = new UserClient()
  const { state } = useContext(AppStateContext)
  const [members, setMembers] = useState<Array<TeamMember>>([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState<TeamMember>()

  useEffect(() => {
    loadMembers()
  }, [state.team])

  const loadMembers = () => {
    if (!state.team) return

    setLoading(true)
    teamClient.getTeamMembers(state.team!.team_id!, 20).then(
      (members: Paginator<TeamMember>) => {
        members.data.forEach((m: TeamMember) => {
          m.loading = false
        })
        setMembers(members.data)
        setLoading(false)
      },
      (error: string) => {
        setLoading(false)
        message.error(error)
      }
    )
  }

  const showDeleteModal = (member: TeamMember) => {
    setMemberToDelete(member)
    setShowModal(true)
  }

  const updatePermission = (member: TeamMember) => {
    members.forEach((m: TeamMember) => {
      if (m.id === member.id) m.loading = true
    })
    setMembers([...members])
    teamClient
      .updateTeamMember(state.team!.team_id!, member.id!, {
        is_admin: !member.is_admin!,
      })
      .then(
        () => {
          members.forEach((m: TeamMember) => {
            if (m.id === member.id) m.is_admin = !member.is_admin
            m.loading = false
          })
          setMembers([...members])
        },
        (error: string) => {
          members.forEach((m: TeamMember) => {
            if (m.id === member.id) m.loading = true
          })
          setMembers([...members])
          message.error(error)
        }
      )
  }

  const verifyUser = (user: User, is_verified: boolean) => {
    members.forEach((m: TeamMember) => {
      if (m.member?.id === user.id) m.loading = true
    })
    setMembers([...members])
    user.is_verified = is_verified
    userClient.updateUser(user.id!, user).then(
      (res: User) => {
        console.log(res)
        members.forEach((m: TeamMember) => {
          if (m.member?.id === user.id) m.member!.is_verified = is_verified
          m.loading = false
        })
        setMembers([...members])
      },
      (error: string) => {
        members.forEach((m: TeamMember) => {
          if (m.member?.id === user.id) m.loading = false
        })
        setMembers([...members])
        message.error(error)
      }
    )
  }

  const deleteModal = () => {
    setShowModal(false)
    members.forEach((m: TeamMember) => {
      if (m.id === memberToDelete!.id) m.loading = true
    })
    setMembers([...members])
    teamClient.removeMember(state.team!.team_id!, memberToDelete!.id!).then(
      () => {
        let m = members.filter((member: TeamMember) => {
          return member.id !== memberToDelete?.id
        })
        setMembers([...m])
      },
      (error: string) => {
        members.forEach((m: TeamMember) => {
          if (m.id === memberToDelete!.id) m.loading = false
        })
        message.error(error)
      }
    )
  }

  const onAddMember = (member: TeamMember) => {
    if (member) {
      members.unshift(member)
      setMembers([...members])
    }
  }

  return (
    <div className="user-popup-content-wrapper">
      <AddMember onAddMember={onAddMember} />
      <div className="members">
        <div
          className="project-overview-card-right"
          style={{ position: "relative" }}
        >
          <div className="project-overview-name-text">Members</div>
        </div>
        <Skeleton loading={loading} active>
          {members.map((member: TeamMember) => {
            return (
              <Spin spinning={member.loading} key={member.id}>
                <div className="search-user">
                  <div className="navbar-user-dropdown-image-wrapper">
                    <img
                      src={require("@/resources/images/unsplash_-v7EOw5SA4I.png")}
                      loading="lazy"
                      alt=""
                      className="object-fit-cover"
                      style={{ display: "none" }}
                    />
                    <div>{member.member!.name.charAt(0)}</div>
                  </div>
                  <div className="user-name">{member.member!.name}</div>
                  <div className="user-button">
                    {member.user_id !== state.team!.team!.creator_id && (
                      <label style={{ marginBottom: 0 }}>
                        <Checkbox
                          checked={member.is_admin}
                          onClick={() => updatePermission(member)}
                        />{" "}
                        Admin
                      </label>
                    )}
                  </div>
                  <div className="user-button">
                    {member.user_id !== state.team!.team!.creator_id && (
                      <label style={{ marginBottom: 0 }}>
                        <Checkbox
                          checked={member.member?.is_verified}
                          onClick={() => verifyUser(member.member!, !member.member?.is_verified)}
                        />{" "}
                        Verified
                      </label>
                    )}
                  </div>
                  <div className="user-remove">
                    {member.user_id !== state.team!.team!.creator_id && (
                      <Button
                        danger
                        type="link"
                        size="small"
                        label="❌"
                        onClick={() => showDeleteModal(member)}
                      />
                    )}
                  </div>
                </div>
              </Spin>
            )
          })}
        </Skeleton>
      </div>
      <ConfirmModal
        message="Are you sure to remove this member?"
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={deleteModal}
      />
    </div>
  )
}

export default TeamMembers
