import React, { useEffect, useState } from "react"
import grid from "@/resources/images/grid-1-1.svg"
import columns from "@/resources/images/columns-1-1.svg"
import arrowDown from "@/resources/images/arrow-down.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import arrowUpRight from "@/resources/images/arrow-up-right-1-1.svg"
import closeIcon from "@/resources/images/close-icon.svg"
import JWTHelper from "@/utilities/JWTHelper"
import { useNavigate } from "react-router-dom"
import ProjectClient from "@/clients/ProjectClient"
import { Paginator } from "@/models/Paginator"
import { Project } from "@/models/Project"
import { Skeleton, message } from "antd"
import moment from "moment"
import ProjectCard from "@/components/project/ProjectCard"
import AccountSettings from "@/components/common/AccountSettings"
import CreateProjecdtModal from "@/components/modals/CreateProjectModal"
import DeleteProjectModal from "@/components/modals/DeleteProjectModal"

const Home: React.ComponentType = () => {
  let navigate = useNavigate()
  let projectClient = new ProjectClient()

  const [projectData, setProjectData] = useState<Paginator<Project>>()
  const [projects, setProjects] = useState<Array<Project>>([])
  const [loading, setLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteProject, setDeleteProject] = useState<Project>()

  useEffect(() => {
    if (!JWTHelper.isLogin()) {
      navigate("/login", { replace: true })
    } else {
      // navigate("/project/accel-today", { replace: true });
      loadProject()
    }
  }, [])

  const loadProject = () => {
    setLoading(true)
    projectClient.getAllProjects({ limit, page, search }).then(
      (data: Paginator<Project>) => {
        setProjectData(data)
        setProjects(projects.concat(data.data))
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  const onCreateProject = (project: Project) => {
    projects.unshift(project)
    setProjects(projects)
    setShowModal(false)
  }

  const onDeleteProject = (project: Project) => {
    let prj = projects.filter((p) => {
      return p.id !== project.id
    })

    setProjects([...prj])
    setShowDeleteModal(false)
  }

  const setProjectToDelete = (project: Project) => {
    setDeleteProject(project)
    setShowDeleteModal(true)
  }

  return (
    <div>
      <div>
        <div
          data-animation="default"
          data-collapse="small"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-home w-nav"
        >
          <div className="navbar-container">
            <a href="#" className="w-nav-brand">
              <div className="navbar-wes-logo">
              <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 61 23" fill="none">
              <path d="M15.75 6.25019L20.6522 22.5379H24.8269L31.5001 0.0830078L27.2305 0.0830078L22.613 16.8135L17.7109 0.0830078L13.7576 0.0830078L8.88707 16.8135L4.23796 0.0830078L0 0.0830078L6.67321 22.5379H10.8479L15.75 6.25019Z" fill="#fff"/>
              <path d="M39.2347 6.0288C34.3959 6.0288 31.1383 9.72911 31.1383 14.5047C31.1383 19.5966 34.6805 22.9174 39.8673 22.9174C41.7965 22.9174 43.8206 22.6644 45.5917 21.5574V17.6674C44.4215 18.6478 42.3974 19.3752 40.4049 19.3752C37.938 19.3752 35.6926 18.2683 35.3763 15.4535L46.5405 15.4535C46.6037 14.9791 46.667 14.2517 46.667 13.6508C46.667 9.44447 44.0103 6.0288 39.2347 6.0288ZM39.1715 9.4761C41.2272 9.4761 42.5872 10.8044 42.7453 12.7653L35.4079 12.7653C35.7558 10.7728 37.1474 9.4761 39.1715 9.4761Z" fill="#fff"/>
              <path d="M55.0285 6.0288C51.1701 6.0288 48.7032 8.17941 48.7032 11.0574C48.7032 13.9671 51.2333 14.9791 53.7318 15.7382C55.8824 16.4023 57.1159 16.7818 57.1159 17.8571C57.1159 18.8376 56.1354 19.5333 54.2378 19.5333C52.024 19.5333 50.3161 18.6478 48.7981 17.193V21.2728C50.2529 22.3165 52.277 22.9174 54.4909 22.9174C58.5074 22.9174 60.9427 20.9565 60.9427 17.8255C60.9427 14.568 58.1912 13.6508 55.5662 12.955C53.5737 12.4174 52.5933 11.943 52.5933 10.9625C52.5933 10.077 53.4472 9.44447 55.1866 9.44447C56.9893 9.44447 58.6656 10.1086 60.0255 11.2156V7.42037C58.8237 6.56646 56.8945 6.0288 55.0285 6.0288Z" fill="#fff"/>
              </svg>
              </div>
            </a>
            <nav role="navigation" className="navbar-menu w-nav-menu">
              <a
                href="#"
                className="start-new-project-button w-inline-block"
                onClick={() => setShowModal(true)}
              >
                <img src={grid} loading="lazy" alt="" />
                <div>Start New Project</div>
              </a>
              <AccountSettings />
            </nav>
            <div className="w-nav-button">
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="page-padding">
          <div className="container">
            <div className="padding-vertical">
              <div className="project-overview-cards-wrapper">
                {/* <div className="project-overview-filters-wrapper">
                  <div className="project-overview-filters">
                    <div className="project-overview-filters-holder">
                      <a href="#" className="w-inline-block"><img src={grid} loading="lazy" alt="" /></a>
                      <a href="#" className="w-inline-block"><img src={columns} loading="lazy" alt="" /></a>
                    </div>
                    <div data-hover="false" data-delay="0" className="w-dropdown">
                      <div className="project-filters-dropdown w-dropdown-toggle">
                        <div className="dropdown-icon w-icon-dropdown-toggle"></div>
                        <div>Last modified</div>
                      </div>
                      <nav className="w-dropdown-list">
                        <a href="#" className="w-dropdown-link">Link 1</a>
                        <a href="#" className="w-dropdown-link">Link 2</a>
                        <a href="#" className="w-dropdown-link">Link 3</a>
                      </nav>
                    </div>
                  </div>
                </div> */}

                {loading && <Skeleton loading={loading} />}
                {!loading &&
                  projects.map((project: Project) => {
                    return (
                      <ProjectCard
                        key={project.alias}
                        project={project}
                        setDeleteProject={setProjectToDelete}
                      />
                    )
                  })}

                {/* <a href="#" className="load-more-button w-button">Load More</a> */}
              </div>
            </div>
          </div>
        </div>
        <CreateProjecdtModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onCreate={onCreateProject}
        />
        <DeleteProjectModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          project={deleteProject!}
          onDelete={onDeleteProject}
        />
      </div>
      <div>
        <div className="page-padding">
          <div className="container">
            <div className="footer">
              <div className="footer-text">© 2022 Edgar Allan</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
