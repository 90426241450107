import React, { useContext, useEffect, useState } from "react"
import { Checkbox, Input, message, Skeleton, Spin } from "antd"
import AddMember from "./AddMember"
import TeamClient from "@/clients/TeamClient"
import { TeamMember } from "@/models/Project"
import { AppStateContext } from "@/components/contexts/AppContext"
import { Paginator } from "@/models/Paginator"
import { Button, ConfirmModal } from "@/components/common"
import UserClient from "@/clients/UserClient"
import { User } from "@/models/User"

const { Search } = Input

const Users: React.ComponentType = () => {
  const teamClient = new TeamClient()
  const userClient = new UserClient()
  const { state } = useContext(AppStateContext)
  const [users, setUsers] = useState<Array<User>>([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState<User>()
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadMembers()
  }, [state.team])

  const loadMembers = () => {
    if (!state.team) return

    setLoading(true)

    userClient.getAllUsers({search: search, limit: 100}).then(
      (res: Paginator<User>) => {
        res.data.forEach((m: TeamMember) => {
          m.loading = false
        })
        setUsers(res.data)
        setLoading(false)
      },
      (error: string) => {
        setLoading(false)
        message.error(error)
      }
    )
  }

  // const showDeleteModal = (member: TeamMember) => {
  //   setMemberToDelete(member)
  //   setShowModal(true)
  // }


  const verifyUser = (user: User, is_verified: boolean) => {
    users.forEach((m: TeamMember) => {
      if (m.member?.id === user.id) m.loading = true
    })
    setUsers([...users])
    user.is_verified = is_verified
    userClient.updateUser(user.id!, user).then(
      (res: User) => {
        console.log(res)
        users.forEach((m: User) => {
          if (m.id === user.id) m.is_verified = is_verified
          m.loading = false
        })
        setUsers([...users])
      },
      (error: string) => {
        users.forEach((m: User) => {
          if (m.id === user.id) m.loading = false
        })
        setUsers([...users])
        message.error(error)
      }
    )
  }

  // const deleteModal = () => {
  //   setShowModal(false)
  //   members.forEach((m: TeamMember) => {
  //     if (m.id === memberToDelete!.id) m.loading = true
  //   })
  //   setMembers([...members])
  //   teamClient.removeMember(state.team!.team_id!, memberToDelete!.id!).then(
  //     () => {
  //       let m = members.filter((member: TeamMember) => {
  //         return member.id !== memberToDelete?.id
  //       })
  //       setMembers([...m])
  //     },
  //     (error: string) => {
  //       members.forEach((m: TeamMember) => {
  //         if (m.id === memberToDelete!.id) m.loading = false
  //       })
  //       message.error(error)
  //     }
  //   )
  // }

  // const onAddMember = (member: TeamMember) => {
  //   if (member) {
  //     members.unshift(member)
  //     setMembers([...members])
  //   }
  // }

  return (
    <div className="user-popup-content-wrapper">
      <div className="members">
        <div
          className="project-overview-card-right"
          style={{ position: "relative" }}
        >
          <div className="project-overview-name-text">Users</div>
          <Input.Search value={search} onChange={(e) => setSearch(e.target.value)} onSearch={loadMembers} />
        </div>
        <Skeleton loading={loading} active>
          {users.map((user: User) => {
            return (
              <Spin spinning={user.loading} key={user.id}>
                <div className="search-user">
                  <div className="navbar-user-dropdown-image-wrapper">
                    <img
                      src={require("@/resources/images/unsplash_-v7EOw5SA4I.png")}
                      loading="lazy"
                      alt=""
                      className="object-fit-cover"
                      style={{ display: "none" }}
                    />
                    <div>{user.name.charAt(0)}</div>
                  </div>
                  <div className="user-name">{user.name}</div>
                  
                  <div className="user-button">
                    <label style={{ marginBottom: 0 }}>
                      <Checkbox
                        checked={user.is_verified}
                        onClick={() => verifyUser(user, !user.is_verified)}
                      />{" "}
                      Verified
                    </label>
                  </div>
                 
                </div>
              </Spin>
            )
          })}
        </Skeleton>
      </div>
    </div>
  )
}

export default Users
