import React, {
  createContext,
  Dispatch,
  useEffect,
  useReducer,
  useState,
} from "react"
import { Actions, reducer, State } from "../reducers/AppReducer"
import UserClient from "@/clients/UserClient"
import TeamClient from "@/clients/TeamClient"
import { User } from "@/models/User"
import { Team, TeamMember } from "@/models/Project"
import JWTHelper from "@/utilities/JWTHelper"
import { useNavigate } from "react-router-dom"

const initialState: State = {
  project: null,
  user: null,
  publishPercent: 0,
  team: null,
  notifications: [],
}

interface IAppStateContext {
  state: State
}

interface IDispatchContext {
  dispatch: Dispatch<any>
}

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
})
export const AppDispatchContext = createContext<IDispatchContext>({
  dispatch: () => {},
})

export const AppProvider: React.ComponentType<any> = ({ children }) => {
  const userClient = new UserClient()
  const teamClient = new TeamClient()
  let navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [interval, setIntervalVal] = useState<any>()

  useEffect(() => {
    userClient.getCurrentUser().then(
      (user: User) => {
        dispatch({ type: Actions.UPDATE_USER, payload: user })
        teamClient.getTeams().then((team: TeamMember) => {
          if (team) {
            dispatch({ type: Actions.UPDATE_TEAM, payload: team })
          }
        })
      },
      () => {
        if(window.location.pathname !== "/" && window.location.pathname !== "/privacy-policy" && window.location.pathname !== "/terms-of-service") {
          JWTHelper.deleteStoredToken()
          navigate("/login", { replace: true })
        }
      }
    )
  }, [])

  // useEffect(() => {
  //   if (!state.project) return

  //   if (interval) clearInterval(interval)

  //   let i = setInterval(() => {
  //     // make the timestamps updated
  //     dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
  //   }, 10000)

  //   setIntervalVal(i)
  // }, [state.project])

  return (
    <AppStateContext.Provider value={{ state }}>
      <AppDispatchContext.Provider value={{ dispatch }}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}
