import React, { useState, useEffect, useContext } from "react"
import { Project, ProjectAsset, ProjectSource } from "@/models/Project"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message, Skeleton } from "antd"
import { Button } from "../common"
import DatabaseIcon from "@/resources/images/62da7c8f0432468b30bc9557_database (1).svg"
import ProjectSourceClient from "@/clients/ProjectSourceClient"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { Actions } from "../reducers/AppReducer"
import AddSourceModal from "@/components/modals/AddSourceModal"
import ProjectLogs from "@/components/modals/ProjectLogsModal"

interface ImportFilesProps {
  project: Project
}

const ImportFiles: React.ComponentType<ImportFilesProps> = (props) => {
  const assetClient = new ProjectAssetClient()
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [loading, setLoading] = useState(false)
  const [importing, setImporting] = useState(false)
  const [percentage, setPercentage] = useState(0)
  const [totalAssets, setTotalAssets] = useState(0)
  const [assets, setAssets] = useState<Array<ProjectAsset>>([])
  const { project } = props
  const sourceClient = new ProjectSourceClient()
  const [assetsLoading, setAssetsLoading] = useState(false)
  const [pages, setPages] = useState(0)
  const [totalPages, setTotalPages] = useState({})
  const [allPages, setAllPages] = useState<Array<any>>([])
  const [showCreateSource, setShowCreateSource] = useState(false)
  const [showLogs, setShowLogs] = useState(false);

  useEffect(() => {
    if (!project) return

    setLoading(true)
    setAssetsLoading(true)

    assetClient.getAssets(project?.id!).then(
      (res: Array<ProjectAsset>) => {
        setAssets(
          res.filter((a) => {
            return a.type === "html"
          })
        )
        setPages(
          res.filter((a) => {
            return a.type === "html"
          }).length
        )
        setAssetsLoading(false)
      },
      (error: string) => {
        message.error(error)
        setAssetsLoading(false)
      }
    )

    project.sources?.map(({ id }) => {
      sourceClient.getSiteMapLocs(id!).then(
        (data: any) => {
          setAllPages(data)
          setTotalPages((prevValue) => ({
            ...prevValue,
            [id!]: data.length,
          }))
        },
        (error) => {
          message.error(error)
        }
      )
    })
  }, [])

  useEffect(() => {
    if (state && state.project) {
      if (!state.project?.is_publishing) setImporting(false)
    }
  }, [state])

  useEffect(() => {
    if (
      Object.keys(totalPages).length === project.sources?.length &&
      !assetsLoading &&
      loading
    )
      setLoading(false)
    return
  }, [assets, totalPages])

  const getTotalPages = () => {
    // this helper function prevents doubling the numbers
    let total = 0
    Object.values(totalPages).map((value: any) => (total += value))
    return total
  }

  const importAssets = (e: any) => {
    e.preventDefault()
    setImporting(true)
    setPercentage(0)
    // console.log(allPages)
    // saveAssets(allPages[0], 0)

    assetClient.updateAllAssets(project.id!).then(
      (res: any) => {
        // publishProject()
        state.project!.is_publishing = true
        state.project!.is_dirty = false
        state.project!.publish_percent = 0
        dispatch({ type: Actions.UPDATE_PUBLISH_PERCENT, payload: 0 })
        dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        setImporting(false)
      },
      (error: string) => message.error(error)
    )
  }

  const saveAssets = (asset: any, current: number) => {
    let totalAssets = allPages.length
    setTotalAssets(totalAssets)
    if (totalAssets <= current) {
      setPercentage(current)
      // setImporting(false)
      publishProject()
      // this.$toasted.success("Assets successfully imported");
      return
    }

    // if (!asset.id) {
    //   // if the asset is a folder, then continue
    //   current = current + 1
    //   setPercentage(current)
    //   saveAssets(assets, assets[current], current)
    //   return
    // }

    // this.currentImportingAsset = asset.url_source;
    asset.send_notification = totalAssets <= current + 1
    if (!asset.is_added) {
      createAsset(asset, current)
    } else {
      let saveAsset = assets.find((a: ProjectAsset) => {
        return asset.url.replace(/\/$/, "") === a.url_source.replace(/\/$/, "")
      })
      if (saveAsset) updateAsset(saveAsset!, current)
    }
  }

  const createAsset = (asset: any, current: number) => {
    assetClient
      .createAsset(project.id!, {
        url_source: [asset.url],
        source_id: project.sources![0].id!,
      })
      .then(
        (asset: ProjectAsset) => {
          current = current + 1
          setPercentage(current)
          saveAssets(allPages[current], current)
        },
        (error: string) => {
          // if an error happens, continue the loop
          console.log(error)
          current = current + 1
          setPercentage(current)
          saveAssets(allPages[current], current)
        }
      )
  }

  const updateAsset = (asset: ProjectAsset, current: number) => {
    assetClient.updateAsset(project.id!, asset.id!, asset).then(
      (asset: ProjectAsset) => {
        current = current + 1
        setPercentage(current)
        saveAssets(allPages[current], current)
      },
      (error: string) => {
        // if an error happens, continue the loop
        console.log(error)
        current = current + 1
        setPercentage(current)
        saveAssets(allPages[current], current)
      }
    )
  }

  // const getPercentage = () => {
  //   if (totalAssets > 0) return ((percentage / totalAssets) * 100).toFixed(2)
  //   else return 0
  // }

  const publishProject = () => {
    // setPublishing(true)
    state.project!.is_publishing = true
    state.project!.is_dirty = false
    state.project!.publish_percent = 0
    dispatch({ type: Actions.UPDATE_PUBLISH_PERCENT, payload: 0 })
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
    setImporting(false)
    projectClient.buildProject(state.project!.id!).then(
      (proj) => {
        // setPublishing(false)
      },
      (error) => {
        message.error(error)
        // setPublishing(false)
      }
    )
  }

  const buttonText = () => {
    if (!state.project?.is_publishing) return "Push to Staging"
    else return "Publishing..."
  }

  const getPublishPercentage = () => {
    if (state.project!.is_publishing) {
      return state.publishPercent.toFixed(2)
      // return 0
    }
  }

  const truncate = (
    fullStr: string,
    strLen = 31,
    separator = "...",
    startChars = 19,
    endChars = 11
  ) => {
    if (fullStr.length <= strLen) return fullStr

    return (
      fullStr.substr(0, startChars) +
      separator +
      fullStr.substr(fullStr.length - endChars)
    )
  }

  const onCreateSource = (source: ProjectSource) => {
    state.project!.sources!.push(source)
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
    setShowCreateSource(false)
  }

  return (
    <React.Fragment>
      <div className="dashboard-card">
        <div className="dashboard-card-left">
          <div className="dashboard-card-icon-wrapper">
            <img src={DatabaseIcon} loading="lazy" alt="" />
          </div>
          <div className="dashboard-card-left-text-wrapper">
            <div className="dashboard-small-text">Files</div>
            {loading ? (
              <Skeleton.Input size="small" />
            ) : (
              <div className="dashboard-big-text">
                {pages > getTotalPages() ? getTotalPages() : pages} of {getTotalPages()} pages imported
              </div>
            )}
          </div>
        </div>
        {state.project && state.project.sources!.length <= 0 && (
          <Button
            label="Add Source"
            className={`button-black-longer w-button ${
              importing && "when-updating"
            }`}
            onClick={() => setShowCreateSource(true)}
            fontSize={20}
          />
        )}
        {state.project && state.project.sources!.length > 0 && (
          <div>
            {loading ? (
              <Skeleton.Input size="large" />
            ) : (
              <Button
                label="Publish to Staging"
                className={`button-black-longer w-button ${
                  importing && "when-updating"
                }`}
                onClick={importAssets}
                loading={importing || state.project!.is_publishing}
                fontSize={20}
              />
            )}
            {state.project!.is_publishing && (
              <div
                className="dashboard-progress-bar"
                style={{ width: `${getPublishPercentage()}%` }}
              ></div>
            )}
          </div>
        )}

        <AddSourceModal
          show={showCreateSource}
          onClose={() => setShowCreateSource(false)}
          onCreate={onCreateSource}
          project={state.project!}
        />
        <ProjectLogs 
          show={showLogs}
          onClose={() => setShowLogs(false)}
          project={project}
        />
      </div>
      <div className="dashboard-action-links-wrapper margin-bottom-40">
        <a
          href="#"
          target="_blank"
          className="dashboard-action-cta w-inline-block"
          rel="noreferrer"
          onClick={() => setShowLogs(true)}
        >
          <div className="text-light">View Logs</div>
        </a>
      </div>
    </React.Fragment>
  )
}

export default ImportFiles
