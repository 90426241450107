import React from "react"
import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <React.Fragment>
    <div className="page-wrapper page-marketing page-terms gradient">
        <div
          data-animation="default"
          className="navbar4_component w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="all"
          data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f12"
          role="banner"
          data-duration="400"
          data-doc-height="1"
        >
          <div
            data-w-id="685e9566-0db0-54ed-1639-4656d8a303a0"
            className="nav-background"
          ></div>
          <div className="navbar4_container">
            <a href="/" className="navbar4_logo-link w-nav-brand">
              <img
                src="images/Wes.svg"
                loading="lazy"
                alt="Wes"
                className="navbar4_logo"
              />
            </a>
            <div className="navbar4_wrapper">
              <a
                href="/login?signin"
                className="button is-hero is-tertiary login w-button"
                >Log In</a
              ><a
                data-w-id="970252ff-6c08-e31d-a189-abba8b33ce83"
                href="/login"
                className="button is-navbar4-button w-button"
                >Book a Demo</a
              >
            </div>
          </div>
        </div>
        <main className="main-wrapper">
          <section className="section_content7">
            <div className="padding-global">
              <div className="container-small">
                <div className="padding-section-large">
                  <div className="content7_component">
                    <div className="margin-bottom margin-small">
                      <h1
                        id="w-node-e07f61d8-1278-16a8-4fdd-ecc47768c926-44d60467"
                        className="heading-style-h2"
                      >
                        Privacy Policy
                      </h1>
                    </div>
                    <div className="text-rich-text w-richtext">
                      <p>
                        This Privacy Policy explains how Wes (&quot;we,&quot;
                        &quot;us,&quot; or &quot;our&quot;) collects, uses,
                        discloses, and protects the personal information of
                        users (&quot;you&quot; or &quot;users&quot;) when you
                        access and use our services. We are committed to
                        protecting your privacy and ensuring the security of
                        your personal information. By using Wes, you consent to
                        the practices described in this Privacy Policy.
                      </p>
                      <h2>1. Information We Collect</h2>
                      <p>
                        <strong>1.1 Personal Information: </strong>When you
                        register for an account on Wes, we may collect personal
                        information, such as your name, email address, and
                        Webflow URLs. We require this information to provide you
                        with access to our services and to communicate with you
                        regarding your account.
                      </p>
                      <p>
                        <strong>1.2 Usage Information: </strong>We may
                        automatically collect certain information about your use
                        of Wes, such as your IP address, device information,
                        browser type, and operating system. This information is
                        used to analyze trends, administer the site, track user
                        movements, and gather demographic information for
                        aggregate use.
                      </p>
                      <h2>2. Use of Information</h2>
                      <p>
                        <strong>2.1 Service Provision:</strong> We use the
                        personal information we collect to provide and improve
                        our services, personalize your experience, and respond
                        to your inquiries or support requests.
                      </p>
                      <p>
                        <strong>2.2 Communication:</strong> We may use your
                        email address to send you important notifications,
                        updates, or promotional materials related to Wes. You
                        can opt-out of receiving marketing communications by
                        following the unsubscribe instructions included in the
                        emails.
                      </p>
                      <p>
                        <strong>2.3 Analytics and Improvements: </strong>We may
                        use the information we collect to analyze user behavior,
                        monitor the effectiveness of our services, and make
                        improvements to Wes.
                      </p>
                      <p>
                        <strong>2.4 Aggregated Data:</strong> We may anonymize
                        and aggregate user data for statistical and analytical
                        purposes. Aggregated data does not identify any
                        individual user and may be shared with third parties for
                        various purposes, including research or marketing.
                      </p>
                      <h2>3. Information Sharing and Disclosure</h2>
                      <p>
                        <strong>3.1 Service Providers:</strong> We may engage
                        third-party service providers to assist us in delivering
                        our services or performing related functions. These
                        service providers will have access to your personal
                        information only to the extent necessary to fulfill
                        their contractual obligations to us and are obligated to
                        keep the information confidential.
                      </p>
                      <p>
                        <strong>3.2 Legal Compliance:</strong> We may disclose
                        your personal information if required to do so by law,
                        legal process, or government request. We may also
                        disclose your information to protect our rights,
                        property, or safety, or the rights, property, or safety
                        of others.
                      </p>
                      <p>
                        <strong>3.3 Business Transfers: </strong>In the event of
                        a merger, acquisition, or sale of all or a portion of
                        our assets, your personal information may be transferred
                        as part of the transaction. We will notify you via email
                        and/or a prominent notice on our website of any change
                        in ownership or uses of your personal information, as
                        well as any choices you may have regarding your
                        information.
                      </p>
                      <h2>4. Data Security</h2>
                      <p>
                        <strong>4.1 Security Measures:</strong> We implement
                        reasonable and appropriate technical and organizational
                        security measures to protect your personal information
                        from unauthorized access, use, alteration, or
                        disclosure.
                      </p>
                      <p>
                        <strong>4.2 Data Retention:</strong> We retain personal
                        information for as long as necessary to fulfill the
                        purposes outlined in this Privacy Policy, unless a
                        longer retention period is required or permitted by law.
                        We will securely delete or anonymize personal
                        information once it is no longer needed.
                      </p>
                      <h2>5. Third-Party Links</h2>
                      <p>
                        Wes may contain links to third-party websites,
                        applications, or services that are not owned or
                        controlled by us. This Privacy Policy does not apply to
                        those third-party services. We encourage you to review
                        the privacy policies of those third parties before
                        providing any personal information.
                      </p>
                      <h2>6. Children&#x27;s Privacy</h2>
                      <p>
                        Wes is not intended for use by individuals under the age
                        of majority in their jurisdiction. We do not knowingly
                        collect personal information from children. If you
                        become aware of any personal information we have
                        inadvertently collected from a child, please contact us
                        immediately, and we will take steps to delete the
                        information.
                      </p>
                      <h2>7. Your Rights</h2>
                      <p>
                        <strong>7.1 Access and Rectification:</strong> You have
                        the right to access and update the personal information
                        we hold about you. If you believe that any information
                        we have is inaccurate or incomplete, please contact us,
                        and we will promptly correct it.
                      </p>
                      <p>
                        <strong>7.2 Data Portability:</strong> Upon your
                        request, we will provide you with a copy of your
                        personal information in a structured, commonly used, and
                        machine-readable format, to the extent feasible.
                      </p>
                      <p>
                        <strong>7.3 Consent Withdrawal:</strong> You have the
                        right to withdraw your consent to the processing of your
                        personal information at any time. Please note that
                        withdrawal of consent may affect your ability to access
                        or use certain features of Wes.
                      </p>
                      <p>
                        <strong>7.4 Account Closure:</strong> You may request
                        the closure of your Wes account at any time. Upon
                        account closure, we will delete or anonymize your
                        personal information, except as required by law or as
                        necessary for legitimate business purposes.
                      </p>
                      <p>
                        <strong>7.5 Marketing Communications:</strong> You can
                        opt-out of receiving marketing communications from us by
                        following the instructions provided in the emails or by
                        contacting us directly. Even if you opt-out, we may
                        still send you non-promotional messages relating to your
                        use of Wes.
                      </p>
                      <h2>8. Changes to this Privacy Policy</h2>
                      <p>
                        We reserve the right to modify or update this Privacy
                        Policy at any time. We will notify you of any material
                        changes by posting the updated policy on our website or
                        through other reasonable means. Your continued use of
                        Wes after the effective date of the updated Privacy
                        Policy constitutes your acceptance of the changes.
                      </p>
                      <h2>9. Contact Us</h2>
                      <p>
                        If you have any questions, concerns, or requests
                        regarding this Privacy Policy or our privacy practices,
                        please contact  your account manager.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="footer4_component">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-vertical padding-small">
                <div className="w-layout-grid footer4_top-wrapper">
                  <a
                    href="/"
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf2-2d593bed"
                    className="footer4_logo-link w-nav-brand"
                  >
                    <img src="images/Wes.svg" loading="lazy" alt="Wes" />
                  </a>
                  <div className="w-layout-grid footer4_link-list">
                    <Link to="/policy"
                      id="w-node-_630333c3-5fc9-5e71-243c-aa5930162276-2d593bed"
                      aria-current="page"
                      className="footer4_link w--current"
                    >
                      Privacy Policy
                    </Link>
                    <Link to="/terms-of-service"className="footer4_link">
                      Terms of Service
                    </Link>
                  </div>
                  <div
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf6-2d593bed"
                    className="w-layout-grid footer4_social-list"
                  >
                    <a
                      href="https://www.edgarallan.com/"
                      target="_blank"
                      className="made-by-ea w-inline-block"
                    >
                      <div>Made By</div>
                      <img
                        src="images/ea-logo-dot.svg"
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default Privacy
