import React from "react"
import logo from "./logo.svg"

import "antd/dist/antd.css" // or 'antd/dist/antd.less'
import "@/resources/css/wes-frontend.webflow.css"
import "@/resources/css/wes-landing-page.css"
import "@/resources/css/app.css"
import Login from "@/components/pages/Login"
import Home from "@/components/pages/Home"
import LandingPage from "@/components/pages/LandingPage"
import Privacy from "@/components/pages/Privacy"
import TermService from "@/components/pages/TermService"
import Project from "@/components/pages/Project"
import Sources from "@/components/pages/Sources"
import Repository from "@/components/pages/Repository"
import Dashboard from "@/components/pages/Dashboard"
import Archives from "@/components/pages/Archives"
import Settings from "@/components/pages/Settings"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { AppProvider } from "@/components/contexts/AppContext"

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-service" element={<TermService />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/project/:alias" element={<Project />}>
              <Route path="" element={<Dashboard />} />
              <Route path="sources" element={<Sources />} />
              <Route path="repository" element={<Repository />} />
              <Route path="archive" element={<Archives />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </div>
      </AppProvider>
    </BrowserRouter>
  )
}

export default App