import React, { useEffect, useState } from "react"
// import "@/resources/css/landing/components.css"
// import "@/resources/css/landing/normalize.css"
// import "@/resources/css/landing/wes-landing-page.css"
// import "@/resources/css/landingpage.css"
import { message } from "antd"
import { Link } from 'react-router-dom'

const LandingPage: React.ComponentType = () => {
  return (
    <React.Fragment>
      <div className="page-wrapper page-marketing">
        <div
          data-animation="default"
          className="nav-marketing-page w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="medium"
          data-w-id="a8785521-102f-62af-a182-3291b00145dd"
          role="banner"
          data-no-scroll="1"
          data-duration="400"
          data-doc-height="1"
        >
          <div className="navbar4_container">
            <a href="/" className="navbar4_logo-link w-nav-brand"
              ><img
                src="/images/645d267bbc959c12bf547bf8_Wes.svg"
                loading="lazy"
                alt="Wes"
                className="navbar4_logo white-icon"
            /></a>
            <div className="nav-links-wrapper">
              <a href="#solutions" className="nav-link">Solutions</a
              ><a href="#integrations" className="nav-link">Integrations</a
              ><a href="#benefits" className="nav-link">Benefits</a>
            </div>
            <div className="navbar4_wrapper">
              <a
                href="/login?signin"
                className="button is-hero is-tertiary login w-button"
                >Log In</a
              ><a
                data-w-id="970252ff-6c08-e31d-a189-abba8b33ce83"
                href="/login"
                className="button is-navbar4-button w-button"
                >Book a Demo</a
              >
            </div>
          </div>
        </div>
        <div className="main-wrapper">
          <div className="background-color-black">
            <section className="section-marketing-hero">
              <div className="padding-global z-index-2">
                <div className="container-large">
                  <div className="text-align-center">
                    <div className="marketing-hero-heading-wrapper">
                      <h1 className="heading-style-huge">
                        Build in Webflow<br />‍<br />Publish Anywhere
                      </h1>
                      <div className="marketing-hero-bracket w-embed">
                        <svg
                          width="100%"
                          height="95"
                          viewBox="0 0 928 95"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM445.293 94.7071C445.683 95.0976 446.317 95.0976 446.707 94.7071L453.071 88.3431C453.462 87.9526 453.462 87.3195 453.071 86.9289C452.681 86.5384 452.047 86.5384 451.657 86.9289L446 92.5858L440.343 86.9289C439.953 86.5384 439.319 86.5384 438.929 86.9289C438.538 87.3195 438.538 87.9526 438.929 88.3431L445.293 94.7071ZM6 7H416V5H6V7ZM445 36V94H447V36H445ZM416 7C432.016 7 445 19.9837 445 36H447C447 18.8792 433.121 5 416 5V7Z"
                            fill="#502DCC"
                          />
                          <path
                            d="M927.333 6C927.333 8.94552 924.946 11.3333 922 11.3333C919.054 11.3333 916.667 8.94552 916.667 6C916.667 3.05448 919.054 0.666667 922 0.666667C924.946 0.666667 927.333 3.05448 927.333 6ZM446.707 94.7071C446.317 95.0976 445.683 95.0976 445.293 94.7071L438.929 88.3431C438.538 87.9526 438.538 87.3195 438.929 86.9289C439.319 86.5384 439.953 86.5384 440.343 86.9289L446 92.5858L451.657 86.9289C452.047 86.5384 452.681 86.5384 453.071 86.9289C453.462 87.3195 453.462 87.9526 453.071 88.3431L446.707 94.7071ZM922 7H476V5H922V7ZM447 36V94H445V36H447ZM476 7C459.984 7 447 19.9837 447 36H445C445 18.8792 458.879 5 476 5V7Z"
                            fill="#502DCC"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="margin-top margin-xhuge">
                      <div className="marketing-hero-par-wrapper">
                        <div className="text-color-grey">
                          <p className="text-size-large">
                            <span className="text-color-white">Wes allows you to build</span> in Webflow and control deployment with the tech stack of your choice.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-marketing-screenshot">
              <div className="padding-global">
                <div className="container-large">
                  <div className="marketing-screenshot-wrapper">
                    <div
                      data-w-id="68ef3f44-f8f7-1218-1340-33ae54260823"
                      className="marketing-spline-element"
                      data-animation-type="spline"
                      data-spline-url="https://prod.spline.design/Lmh8oiTv9cGHCzE4/scene.splinecode"
                    >
                      <canvas></canvas>
                    </div>
                    <img
                      src="/images/65bd0a6394cd6d1d9ff59a6d_Screenshot.png"
                      loading="lazy"
                      sizes="90vw"
                      srcSet="
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-500.png   500w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-800.png   800w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-1080.png 1080w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-1600.png 1600w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-2000.png 2000w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot-p-2600.png 2600w,
                        /images/65bd0a6394cd6d1d9ff59a6d_Screenshot.png        2624w
                      "
                      alt=""
                      className="marketing-screenshot-img"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section id="solutions" className="section-solutions">
            <div className="padding-global">
              <div className="container-large">
                <div className="padding-section-large">
                  <div className="layout303_component align-center relative">
                    <div className="margin-bottom margin-xxlarge">
                      <h3>Solutions to Extend &amp; Scale</h3>
                    </div>
                    <div className="w-layout-grid layout303_listy z-index-2">
                      <div className="">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfb5e63fabcb7ab54b924_sliders_purple.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Custom Deployment Flow</h3>
                        </div>
                        <p>
                          With Wes, you can create custom deployment, into your
                          environment. This gives you the power to tailor your
                          deployment process to your unique needs, ensuring a
                          perfect fit for your organization.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfb62b5e5cf6f5f481efa_shield.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            GDPR Specific Server Deployment
                          </h3>
                        </div>
                        <p>
                          Wes supports GDPR-specific server deployment, helping
                          you meet stringent data protection requirements without
                          breaking a sweat.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfb6319116cf94079d7cd_loader.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Change Detection</h3>
                        </div>
                        <p>
                          Wes&#x27;s change detection feature keeps you informed
                          about every update, so you can deploy efficiently and
                          effectively.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfb652da12daf6c1a7028_rotate-ccw.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Version Control</h3>
                        </div>
                        <p>
                          Roll back a single page, section or the entire build.
                          Wes allows you to keep track of every change with
                          Wes&#x27;s robust version control.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfbf819116cf9407a7579_edit-3.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Per Page Publishing</h3>
                        </div>
                        <p>
                          Update what you need, when you need it. With Wes&#x27;s
                          per page publishing, you can make changes to individual
                          pages without updating the full build, saving you time
                          and resources.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfbf9565e546f608c071f_package.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Owned CDN</h3>
                        </div>
                        <p>
                          Deploy your website to a custom-configured CDN, gaining
                          complete control over content delivery. Enjoy faster
                          load times, improved user experience, and optimized
                          scalability with a tailored content delivery solution.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfaf319116cf940791fae_lock.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Locked CSS</h3>
                        </div>
                        <p>
                          Keep your site&#x27;s look and feel secure with
                          Wes&#x27;s Locked CSS feature. Prevent unauthorized
                          changes and maintain consistency across your site.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfbfa0cf209d81b05b32d_git-merge.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Server-Side Code Injections
                          </h3>
                        </div>
                        <p>
                          Merge Webflow code with other code systems effortlessly.
                          Wes&#x27;s server-side code injections allow for
                          seamless integration, giving you more flexibility and
                          control.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfbfb5d697295b1a45952_calendar.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Managed Release Schedule
                          </h3>
                        </div>
                        <p>
                          Plan your releases with precision. With Wes&#x27;s
                          managed release schedule, you can coordinate your
                          deployments to align with your timelines and business
                          goals.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfabd26cf430c1828fbcb_globe%20(2).svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Translations</h3>
                        </div>
                        <p>
                          Speak your audience&#x27;s language. Wes&#x27;s creates
                          a surface to connect to a variety of translation
                          systems.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/645dfbfdbd8be67ef49f1474_search%20(1).svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Unified Site Map</h3>
                        </div>
                        <p>
                          Consolidate multiple Webflow instances into one build
                          with a unified site map, boosting the indexability of
                          your site.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="integrations" className="section-integrations">
            <div className="integrations-gradient-bg"></div>
            <div className="padding-global z-index-2">
              <div className="container-large">
                <div
                  className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop"
                >
                  <div className="margin-bottom margin-xxlarge padding-mob-small">
                    <div className="text-color-white">
                      <div className="text-align-center text-align-left-mob">
                        <div className="max-width-large align-center">
                          <div className="margin-bottom margin-small">
                            <h2>Wes Integrations</h2>
                          </div>
                          <p className="text-size-medium">
                            Wes seamlessly integrates with authentication
                            platforms like Auth0 and Okta, code management systems
                            like GitHub, and popular cloud environments including
                            S3, Azure, and Salesforce Cloud, enabling a seamless
                            workflow and enhancing the power of your Webflow
                            deployments.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid layout373_component">
                    <div
                      id="w-node-_8c0c53ea-501b-db23-9835-2df80fe319b2-a74ba3ca"
                      className="w-layout-grid layout373_row"
                    >
                      <div
                        id="w-node-_8c0c53ea-501b-db23-9835-2df80fe319b3-a74ba3ca"
                        className="layout373_card-large border-bottom"
                      >
                        <div className="layout373_card-large-content">
                          <div className="layout373_card-large-content-top">
                            <div className="margin-bottom margin-small">
                              <div className="display-flex">
                                <div className="margin-right margin-large">
                                  <img
                                    src="/images/645d3e20da4f2744fa962968_Auth0.svg"
                                    loading="lazy"
                                    alt=""
                                    className="icon-height-auth0 white-icon"
                                  />
                                </div>
                                <img
                                  src="/images/645d3e2023b2e6668457b953_Okta.svg"
                                  loading="lazy"
                                  alt=""
                                  className="icon-height-okta white-icon"
                                />
                              </div>
                            </div>
                            <div className="text-color-white">
                              <p className="text-size-regular">
                                Enhance security with Wes&#x27;s seamless
                                integration with authentication platforms like
                                Auth0 and Okta. Safeguard your web applications
                                and ensure secure access with industry-leading
                                authentication and authorization mechanisms.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="layout373_card-small border-bottom-left">
                        <div className="layout373_card-small-content">
                          <div className="layout373_card-small-content-top">
                            <div className="margin-bottom margin-xsmall">
                              <img
                                src="/images/645d3e200b6a42813e754905_GitHub.svg"
                                loading="lazy"
                                alt=""
                                className="icon-height-github white-icon"
                              />
                            </div>
                            <div className="text-color-white">
                              <p className="text-size-regular">
                                Streamline your code management with Wes&#x27;s
                                integration with GitHub.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="layout373_card-small border-bottom-left border-left-tablet"
                      >
                        <div className="layout373_card-small-content">
                          <div className="layout373_card-small-content-top">
                            <div className="margin-bottom margin-xsmall">
                              <img
                                src="/images/645d3e2104cbb22b95b53a2d_AWS.svg"
                                loading="lazy"
                                alt=""
                                className="icon-height-aws white-icon"
                              />
                            </div>
                            <div className="text-color-white">
                              <p className="text-size-regular">
                                Leverage the power of Wes&#x27;s integration with
                                Amazon S3 for efficient cloud storage.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_8c0c53ea-501b-db23-9835-2df80fe319cb-a74ba3ca"
                      className="w-layout-grid layout373_row"
                    >
                      <div
                        id="w-node-_8c0c53ea-501b-db23-9835-2df80fe319cc-a74ba3ca"
                        className="layout373_card-large"
                      >
                        <div className="layout373_card-large-content">
                          <div className="layout373_card-large-content-top">
                            <div className="margin-bottom margin-small">
                              <img
                                src="/images/645d3e21bc959c12bf6e60b7_MsAzure.svg"
                                loading="lazy"
                                alt=""
                                className="icon-height-msazure white-icon"
                              />
                            </div>
                            <div className="text-color-white">
                              <p className="text-size-regular">
                                Harness the capabilities of Microsoft Azure with
                                Wes&#x27;s integration. Seamlessly deploy your
                                Webflow projects to the Azure cloud, taking
                                advantage of its robust infrastructure,
                                scalability, and vast array of services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_8c0c53ea-501b-db23-9835-2df80fe319d3-a74ba3ca"
                        className="layout373_card-large border-left"
                      >
                        <div className="layout373_card-large-content">
                          <div className="layout373_card-large-content-top">
                            <div className="margin-bottom margin-small">
                              <img
                                src="/images/645d3e217fa6668c2efdf3e1_Salesforce.svg"
                                loading="lazy"
                                alt=""
                                className="icon-height-salesforce white-icon"
                              />
                            </div>
                            <p className="text-size-regular text-color-white">
                              Unleash the potential of your customer relationship
                              management with Wes&#x27;s integration with
                              Salesforce Cloud. Seamlessly connect your
                              Webflow-powered websites or applications to
                              Salesforce, enabling seamless data synchronization
                              and empowering your sales and marketing teams.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="benefits" className="section-benefits">
            <div className="padding-global">
              <div className="container-large">
                <div
                  className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop"
                >
                  <div className="w-layout-grid layout353_component relative">
                    <div className="layout353_content-left z-index-2">
                      <div className="margin-bottom margin-small padding-mob-small">
                        <h2 className="blend-difference">Benefits for Enterprises</h2>
                      </div>
                    </div>
                    <div
                      id="w-node-_8b7bb294-bc26-1b20-e6ea-23ed3f59f5ae-a74ba3ca"
                      className="layout353_content-right z-index-2"
                    >
                      <div className="layout353_content-item content-item-1">
                        <div className="margin-bottom margin-medium">
                          <h3 className="heading-style-h4">Deployment Control</h3>
                        </div>
                        <p className="text-size-regular">
                        Wes puts you in the driver's seat, offering unparalleled control over your deployment process. From custom deployments to specific environments, to per-page publishing, Wes allows you to tailor your deployment strategy to your organization's unique needs. With the ability to roll back a single page, section, or the entire build, you can manage changes effectively and maintain a robust version control.
                        </p>
                      </div>
                      <div className="layout353_content-item content-item-2">
                        <div className="margin-bottom margin-medium">
                          <h3 className="heading-style-h4">Security</h3>
                        </div>
                        <p className="text-size-regular">
                        Wes is designed with security at its core. From GDPR-specific server deployments to Locked CSS that prevents unauthorized changes, Wes helps you maintain a secure and compliant environment. Additionally, Wes's seamless integration with other code systems through server-side code injections enhances your security posture.
                        </p>
                      </div>
                      <div className="layout353_content-item content-item-3">
                        <div className="margin-bottom margin-medium">
                          <h3 className="heading-style-h4">Scalability</h3>
                        </div>
                        <p className="text-size-regular">
                        Wes is built to scale with your organization. Whether you're deploying to your custom configured CDN or managing a release schedule that aligns with your business goals, Wes supports your growth. Its change detection feature ensures you stay informed about every update, enabling efficient and effective deployments.
                        </p>
                      </div>
                      <div className="layout353_content-item content-item-4">
                        <div className="margin-bottom margin-medium">
                          <h3 className="heading-style-h4">Flexibility</h3>
                        </div>
                        <p className="text-size-regular">
                        Wes provides the flexibility your enterprise needs. With features like server-side code injections for seamless integration with other code systems, and a surface to connect to a variety of translation systems, Wes allows you to customize your Webflow experience. Plus, with the 'Draft' component, you have the flexibility to control when pages move into production.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-exclusive">
            <div className="exclusive-gradient-bg"></div>
            <div className="padding-global z-index-2">
              <div className="container-large">
                <div className="padding-section-large">
                  <div className="exclusive-component">
                    <div className="exclusive_img-wrapper">
                      <img
                        src="/images/65c0b8b7028c93883d48744b_Wes%20App.png"
                        loading="lazy"
                        width="624"
                        sizes="(max-width: 767px) 90vw, (max-width: 991px) 43vw, 44vw"
                        alt=""
                        srcSet="
                          /images/65c0b8b7028c93883d48744b_Wes%20App-p-500.png   500w,
                          /images/65c0b8b7028c93883d48744b_Wes%20App-p-800.png   800w,
                          /images/65c0b8b7028c93883d48744b_Wes%20App-p-1080.png 1080w,
                          /images/65c0b8b7028c93883d48744b_Wes%20App.png        1248w
                        "
                        className="full-width"
                      />
                    </div>
                    <div className="exclusive_text-wrapper">
                      <div className="text-color-white">
                        <h2>Webflow Enterprise Exclusive</h2>
                        <div className="exclusive-columns-wrapper">
                          <div className="exclusive-colum">
                            <h4 className="text-size-regular text-weight-semibold">
                              Only for Enterprise
                            </h4>
                            <div className="margin-top margin-xsmall">
                              <p>
                                Wes is exclusively available to Webflow Enterprise
                                customers. As a Webflow Enterprise customer, you
                                have the unique opportunity to leverage Wes&#x27;s
                                powerful features and benefits.
                              </p>
                            </div>
                          </div>
                          {/* <div className="exclusive-colum">
                            <h4 className="text-size-regular text-weight-semibold">
                              Only for Enterprise
                            </h4>
                            <div className="margin-top margin-xsmall">
                              <p>
                                Wes is exclusively available to Webflow Enterprise
                                customers. As a Webflow Enterprise customer, you
                                have the unique opportunity to leverage Wes&#x27;s
                                powerful features and benefits.
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="exclusive-bttn-group">
                        <a
                          href="/login"
                          className="button is-secondary is-text-white w-button"
                          >Book a Demo</a
                        >
                      </div>
                    </div>
                  </div>
                  <div className="book-demo-big">
                    <a href="/login" className="book-demo-link w-inline-block"
                      ><h2 className="book-demo-text">
                        Book a Demo for Webflow Enterprise
                      </h2>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer4_component">
              <div className="padding-global">
                <div className="container-large">
                  <div className="padding-vertical padding-small">
                    <div className="w-layout-grid footer4_top-wrapper">
                      <a
                        href="/"
                        id="w-node-fa14a088-817f-a7c5-285f-b156b3c60dc2-a74ba3ca"
                        className="footer4_logo-link w-nav-brand"
                        ><img
                          src="/images/645d267bbc959c12bf547bf8_Wes.svg"
                          loading="lazy"
                          alt="Wes"
                          className="white-icon"
                      /></a>
                      <div className="w-layout-grid footer4_link-list">
                        <a
                          id="w-node-fa14a088-817f-a7c5-285f-b156b3c60dc5-a74ba3ca"
                          href="/privacy-policy"
                          className="footer4_link text-color-white"
                          >Privacy Policy</a
                        ><a
                          href="/terms-of-service"
                          className="footer4_link text-color-white"
                          >Terms of Service</a
                        >
                      </div>
                      <div
                        id="w-node-fa14a088-817f-a7c5-285f-b156b3c60dc9-a74ba3ca"
                        className="w-layout-grid footer4_social-list"
                      >
                        <a
                          href="https://www.edgarallan.com/"
                          target="_blank"
                          className="made-by-ea text-color-white w-inline-block"
                          ><div>Made By</div>
                          <img
                            src="/images/645e37e7e1e9f6307c1c2144_ea-logo-dot.svg"
                            loading="lazy"
                            alt=""
                            className="image"
                        /></a>
                        <div className="display-none">
                          <a
                            href="https://www.linkedin.com/company/edgar-allan"
                            target="_blank"
                            className="footer4_social-link w-inline-block"
                            ><div className="social-icon w-embed">
                              <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                                  fill="CurrentColor"
                                />
                              </svg></div></a
                          ><a
                            href="https://www.youtube.com/edgarallan"
                            target="_blank"
                            className="footer4_social-link w-inline-block"
                            ><div className="social-icon w-embed">
                              <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M18.5399 4.33992L19.9999 4.48992C21.7284 4.68529 23.0264 6.16064 22.9999 7.89992V16.0999C23.0264 17.8392 21.7284 19.3146 19.9999 19.5099L18.5999 19.6599C14.2315 20.1099 9.82835 20.1099 5.45991 19.6599L3.99991 19.5099C2.27143 19.3146 0.973464 17.8392 0.999909 16.0999V7.89992C0.973464 6.16064 2.27143 4.68529 3.99991 4.48992L5.39991 4.33992C9.76835 3.88995 14.1715 3.88995 18.5399 4.33992ZM11.1099 15.2199L14.9999 12.6199H15.0599C15.2695 12.4833 15.3959 12.2501 15.3959 11.9999C15.3959 11.7497 15.2695 11.5165 15.0599 11.3799L11.1699 8.77992C10.9402 8.62469 10.6437 8.60879 10.3987 8.73859C10.1538 8.86839 10.0004 9.12271 9.99991 9.39992V14.5999C10.0128 14.858 10.1576 15.0913 10.3832 15.2173C10.6088 15.3433 10.8834 15.3443 11.1099 15.2199Z"
                                  fill="currentColor"
                                />
                              </svg></div></a
                          ><a
                            href="https://twitter.com/EdgarAllanCo"
                            target="_blank"
                            className="footer4_social-link w-inline-block"
                            ><div className="social-icon w-embed">
                              <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.9728 6.7174C20.5084 7.33692 19.947 7.87733 19.3103 8.31776C19.3103 8.47959 19.3103 8.64142 19.3103 8.81225C19.3154 11.7511 18.1415 14.5691 16.0518 16.6345C13.962 18.6999 11.1312 19.8399 8.19405 19.7989C6.49599 19.8046 4.81967 19.4169 3.29642 18.6661C3.21428 18.6302 3.16131 18.549 3.16162 18.4593V18.3604C3.16162 18.2313 3.26623 18.1267 3.39527 18.1267C5.06442 18.0716 6.67402 17.4929 7.99634 16.4724C6.48553 16.4419 5.12619 15.5469 4.5006 14.1707C4.46901 14.0956 4.47884 14.0093 4.52657 13.9432C4.57429 13.8771 4.653 13.8407 4.73425 13.8471C5.19342 13.8932 5.65718 13.8505 6.1002 13.7212C4.43239 13.375 3.17921 11.9904 2.99986 10.2957C2.99349 10.2144 3.02992 10.1357 3.096 10.0879C3.16207 10.0402 3.24824 10.0303 3.32338 10.062C3.77094 10.2595 4.25409 10.3635 4.74324 10.3676C3.28184 9.40846 2.65061 7.58405 3.20655 5.92622C3.26394 5.76513 3.40181 5.64612 3.5695 5.61294C3.73718 5.57975 3.90996 5.63728 4.02432 5.76439C5.99639 7.86325 8.70604 9.11396 11.5819 9.25279C11.5083 8.95885 11.4721 8.65676 11.4741 8.35372C11.501 6.76472 12.4842 5.34921 13.9634 4.76987C15.4425 4.19054 17.1249 4.56203 18.223 5.71044C18.9714 5.56785 19.695 5.31645 20.3707 4.96421C20.4202 4.93331 20.483 4.93331 20.5325 4.96421C20.5634 5.01373 20.5634 5.07652 20.5325 5.12604C20.2052 5.87552 19.6523 6.50412 18.9509 6.92419C19.5651 6.85296 20.1685 6.70807 20.7482 6.49264C20.797 6.45942 20.8611 6.45942 20.9099 6.49264C20.9508 6.51134 20.9814 6.54711 20.9935 6.59042C21.0056 6.63373 20.998 6.68018 20.9728 6.7174Z"
                                  fill="CurrentColor"
                                />
                              </svg></div
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LandingPage
