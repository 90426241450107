import { ProjectAsset, ProjectSource } from "@/models/Project"
import React, { useContext, useEffect, useState } from "react"
import { message } from "antd"
import moment from "moment"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { Button } from "@/components/common"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import {
  truncateUrl,
  getStagingUrl,
  getTruncatedStagingUrl,
} from "@/utilities/String"
import {
  FolderOutlined,
  FolderOpenOutlined,
  CodeOutlined,
  FileImageOutlined,
  FileTextOutlined,
  ChromeOutlined,
} from "@ant-design/icons"

interface RepositoryItemProps {
  alias: string
  asset: ProjectAsset
  assets: Array<ProjectAsset>
  index: number
  onClick: any
}

const RepositoryItem: React.ComponentType<RepositoryItemProps> = (
  props: RepositoryItemProps
) => {
  const { alias, asset, assets, index } = props
  const [rotate, setRotate] = useState(0)
  const [loading, setLoading] = useState(false)
  const assetClient = new ProjectAssetClient()
  const { state } = useContext(AppStateContext)

  useEffect(() => {
    setRotate(asset.is_close ? 0 : 90)
  }, [asset.is_close])

  let highest = asset.deep!
  const isHidden = (deep: number, index: number): boolean => {
    let hidden = false
    if (asset.deep === 1) return false

    while (index >= 0 && assets[index].deep! >= 1) {
      // loop through all the files until found a close folder
      index = index - 1
      if (assets[index] && assets[index].type === "folder") {
        if (assets[index].deep! >= asset.deep!) continue
        else if (highest > assets[index].deep! && !assets[index].is_close)
          highest = assets[index].deep!

        if (assets[index].is_close && assets[index].deep! < highest) {
          hidden = true
          break
        }

        if (assets[index].deep === 1)
          // when reached the root directory, break
          break
      }
    }

    return hidden
  }

  const createVersion = () => {
    setLoading(true)
    assetClient.updateAsset(state.project?.id!, asset.id!, asset).then(
      (res: ProjectAsset) => {
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const updateFolder = () => {
    setLoading(true)
    assetClient.updateFolderAssets(state.project?.id!, asset.path!).then(
      (res: any) => {
        setLoading(false)
      }, (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const getIcon = (type: string) => {
    switch (type) {
      case "html":
        return <ChromeOutlined />
      case "file/image":
      case "image":
        return <FileImageOutlined />
      case "file/js":
      case "file/css":
        return <CodeOutlined />
      case "file/text":
        return <FileTextOutlined />
    }
  }

  if (isHidden(asset.deep!, index)) return null

  if (asset.type === "folder") {
    return (
      // <div {...props} className="repository-single-item-wrapper">
      //   <div className="repository-name-wrapper-w-padding">
      //     <div className="page-grid-item-text" style={{ paddingLeft: 30 * (asset.deep! - 1) }}>
      //       {asset.is_close && <FolderOutlined />}
      //       {!asset.is_close && <FolderOpenOutlined />}
      //       <span style={{ paddingLeft: 10 }}>{asset.name}</span>
      //     </div>
      //   </div>
      // </div>

      <div
        {...props}
        className="repository-two-dropdown-toggle w-dropdown-toggle"
      >
        <div className="repository-two-single-item-wrapper">
          <div
            className="repository-two-name-wrapper-w-padding"
            style={{ paddingLeft: 30 * (asset.deep! - 1) }}
          >
            <img
              src="https://assets.website-files.com/625e72e3b276d01282f085dd/629f236fc174c61927147563_folder.svg"
              loading="lazy"
              alt=""
            />
            <div>{truncateUrl(asset.name!, 30)}</div>
            <img
              src="https://assets.website-files.com/625e72e3b276d01282f085dd/629f1ea28e589e572b900ffd_Vector-3.svg"
              loading="lazy"
              alt=""
              className="repository-two-dropdown-arrow"
              style={{
                transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${rotate}deg) skew(0deg, 0deg)`,
                transitionDuration: "0.3s",
                transformStyle: "preserve-3d",
              }}
            />
            
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <Button
              label="Update"
              type="link"
              className="update-asset w-link"
              onClick={updateFolder}
              loading={loading}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div {...props} className="repository-two-single-item-wrapper">
        <div
          className="repository-two-name-wrapper-w-padding"
          style={{ paddingLeft: 30 * (asset.deep! - 1) }}
        >
          <div>
            <img
              src="https://assets.website-files.com/625e72e3b276d01282f085dd/629f1aa59b77d8d18f0634d9_layout.svg"
              loading="lazy"
              alt=""
            />
            <span style={{ paddingLeft: 10 }}>
              {truncateUrl(asset.name!, 30)}
            </span>
          </div>
        </div>
        <div className="repository-name-wrapper">
          <a
            href={asset.path && getStagingUrl(asset.path, alias)}
            target="_blank"
            className="page-grid-item-link w-inline-block asset-url"
            rel="noreferrer"
          >
            <div className="project-overview-link">
              {asset.path && getTruncatedStagingUrl(asset.path, alias)}
            </div>
          </a>
        </div>
        <div className="repository-name-wrapper">
          <div className="page-grid-item-text">
            {moment(asset.updated_at).fromNow()}
          </div>
        </div>
        <div className="page-grid-item-text">{asset.type}</div>
        <div className="page-grid-item-text">
          {/* <a href="#" className="update-asset w-link" onClick={createVersion}>Update</a> */}
          <Button
            label="Update"
            type="link"
            className="update-asset w-link"
            onClick={createVersion}
            loading={loading}
          />
        </div>
      </div>
    )
  }
}

export default RepositoryItem
